@font-face {
	font-family: Helvetica Neue LT W01_35 Thin;
	font-display: auto;
	src: url(./fonts/4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2) format("woff2"),
		url(./fonts/e8f8260f-dbee-4daa-81a4-0e415715c569.ttf) format("truetype");
}

@font-face {
	font-family: Helvetica World W01;
	font-display: auto;
	src: url(./fonts/80e34113-e21f-498a-81ca-4cdd4c2ee6b4.woff2) format("woff2"),
		url(./fonts/4686edb2-41cf-4e4b-87ee-b09427921b44.woff) format("woff"),
		url(./fonts/cb1c872a-9eb4-4fdb-b73c-5134dd5f1f3c.ttf) format("truetype");
}
.btn {
	text-transform: uppercase;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.17);
	text-align: left;
	position: relative;

	&-primary {
		&:not(.btn-no-icon) {
			padding-right: 56px;

			&:after {
				transition: all 0.3s ease-in-out;
				background-image: str-replace(
					url("data:image/svg+xml,%3csvg viewBox='0 0 20 14.1' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#{$white}'  d='M13.5.2c-.3-.3-.7-.3-1 0s-.3.7 0 1l5.1 5.1H.7c-.4 0-.7.3-.7.7s.3.7.7.7h16.9l-5.1 5.1c-.3.3-.3.7 0 1s.7.3 1 0l6.3-6.3c.3-.3.3-.7 0-1L13.5.2z'/%3e%3c/svg%3e"),
					"#",
					"%23"
				);
				background-position: 0;
				background-repeat: no-repeat;
				content: "";
				height: 100%;
				position: absolute;
				right: 1.5rem;
				top: 50%;
				transform: translateY(-50%);
				width: 1.25rem;
			}

			&:hover {
				&:after {
					transform: translateY(-50%) translateX(5px);
				}
			}
		}
	}
}

.hero_block {
	&-text {
		left: 25%;
		transform: translate(-25%, -50%);
		text-align: left;
	}
	&-head {
		font-weight: 700;
		text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
	}

	&-visual {
		max-height: inherit;
		min-height: 300px;
	}
}

.nav {
	&bar {
		box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
		min-height: 80px;

		&-brand {
			img {
				height: 35px;
			}
		}
	}
	&-link {
		font-size: 1.25rem;
	}
}

.nav-tabs .nav-link {
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #000;
	border: none;
	border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active {
	font-weight: 700;
	border-bottom: 3px solid var(--wb-blue);
}

.dropdown-toggle:after {
	border: none;
	display: inline-block;
	width: 16px;
	height: 8px;
	background-repeat: no-repeat;
	background-image: str-replace(
		url("data:image/svg+xml,%3csvg viewBox='0 0 14.1 7.9' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' fill='none' stroke='#{$dark}'  d='M13.3.8L7 7 .8.8'/%3e%3c/svg%3e"),
		"#",
		"%23"
	);
}

aside {
	.rte {
		ul {
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 1.5rem;

				&:before {
					content: "";
					border-left: 0.3125rem solid $primary;
					height: 95%;
					left: 0;
					position: absolute;
				}
			}
		}
	}
}

ol {
	& > li {
		font-size: 16px;
	}
}

.rte {
	& > ul {
		padding-left: 0;
	}
	& > ol {
		padding-left: 19px;
	}

	ul {
		li {
			list-style-type: none;

			&:before {
				content: "+";
				margin-right: 10px;
				font-weight: bold;
			}
			// &:hover {

			//     &:before {
			//         color: $primary;
			//     }
			// }
		}
	}
}
.footer {
	&-links {
		color: $dark;
		font-size: smaller;

		a {
			text-decoration: underline;
		}
	}
	&-socialmedia {
		a {
			background-color: $secondary;
			color: $white;

			&:hover {
				background-color: $primary;
				color: $white;
			}
		}
	}
}
.poi {
	&-icon {
		.fa-circle {
			stroke: $primary;
			stroke-width: 16px;
		}
	}
	&-label-content {
		font-size: 16px;
	}
}

@include media-breakpoint-up(sm) {
	.navbar-brand {
		img {
			height: 50px;
		}
	}
}

@include media-breakpoint-up(lg) {
	body {
		font-size: $font-size-lg;
	}

	.navbar {
		min-height: 133px;
		padding-top: 17px;
		padding-bottom: 17px;
		padding-left: 2.8rem;
		padding-right: 2.8rem;

		> .container-fluid {
			align-items: flex-end;
		}

		&-brand img {
			height: 59px;
		}

		.navbar-nav {
			line-height: 16px;
		}

		.nav-link {
			font-size: 20px;
			margin-left: 30px;
			padding: 0;
		}
	}
	.hero_block {
		&-visual {
			max-height: 575px;
		}
		&-text {
			top: 75%;
		}
	}
	.dropdown {
		&-menu {
			margin-left: 30px;
			box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
		}
		&-item {
			&.active {
				color: $primary;
				background-color: $white;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.navbar {
		.nav-link {
			font-size: 24px;
		}
	}
	.hero_block {
		&-visual {
			max-height: 675px;
		}
	}
}

/* IE11 hack */
@media all and (-ms-high-contrast: none) {
	.hero_block picture {
		max-height: inherit;
	}
}