//import bootstrap functions
@import "~bootstrap/scss/functions";

//import bootstrap components
@import "~bootstrap/scss/variables";
/*import custom theme*/
@import "./variables";


//add white button
$theme-colors: map-merge(
  $theme-colors,
  (
    "white": $white,
  )
);

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "./custom";

.wb-header-on-top {
  z-index: 10;
}
.data-card {
  height: 100%;
}

.maplegend {
  top: 50px;
  padding: 5px;
  z-index: 2;
  background-color: white;
}

@media (min-width: 576px) {

  .wb-container {
      max-width: 540px;
  }

}


@media (min-width: 768px) {

  .wb-container {
    max-width: 720px;
  }


}


@media (min-width: 992px) {
   .wb-container {
    max-width: 960px;
  }

}
  @media (min-width: 1200px) {
     .wb-container {
      max-width: 1140px;
    }

  }
  


